import React, { forwardRef, useId } from "react";

import { Controller, useFormContext } from "react-hook-form";
import { twJoin, twMerge } from "tailwind-merge";

interface TextAreaProps extends React.InputHTMLAttributes<HTMLTextAreaElement> {
  label: string;
  rows?: number;
  required?: boolean;
  error?: string;
}

const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  ({ label, error, required, value, rows = 4, id, className, ...props }, ref) => {
    const fallbackId = useId();
    id = id ?? fallbackId;

    return (
      <div className="group">
        <label
          htmlFor={id}
          className={twJoin("fdc-input-field-label", value && "fdc-input-field-label-active")}
        >
          <span>
            {label}
            {required && "*"}
          </span>
        </label>
        <div
          className={twJoin(
            "fdc-input-field-wrapper",
            "relative h-48",
            error && "fdc-input-field-error"
          )}
        >
          <span
            className={twJoin(
              "absolute right-3.5 top-1.5 z-1 body-xs text-dark",
              value ? "text-opacity-40" : "text-opacity-20"
            )}
          >
            {value?.toString().length}
          </span>
          <textarea
            ref={ref}
            id={id}
            rows={rows}
            value={value}
            placeholder={`${label}${required ? "*" : ""}`}
            className={twMerge("fdc-input-field resize-none", className)}
            {...props}
            aria-invalid={error ? true : false}
            aria-describedby={error ? `error-message-${id}` : undefined}
          />
        </div>
        {error && (
          <p id={`error-message-${id}`} className="fdc-input-field-error-text">
            {error}
          </p>
        )}
      </div>
    );
  }
);

TextArea.displayName = "TextArea";
export interface ControlledTextAreaProps extends TextAreaProps {
  name: string;
}

const ControlledTextArea = ({ name, ...props }: ControlledTextAreaProps) => {
  const { control, formState } = useFormContext();
  const error = formState.errors[name];
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => <TextArea {...field} {...props} error={error?.message as string} />}
    />
  );
};

export default ControlledTextArea;
