import React, { forwardRef, useState, useEffect, useId } from "react";

import { Controller, useFormContext } from "react-hook-form";
import { twJoin, twMerge } from "tailwind-merge";

import { COUNTRY_CODES } from "./data";

import styles from "./phone-input.module.scss";

export type PhoneNumberParams = {
  country_code: string;
  phone_number: string;
};

interface PhoneInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  required?: boolean;
  error?: string;
  /** A handler function for this component to pass the full phone number (country code + digits) to the parent.
   * This has the final value you need*/
  onFullNumberChange: (params: PhoneNumberParams) => void;
}

const PhoneInput = forwardRef<HTMLInputElement, PhoneInputProps>(
  (
    { id, label, required, error, value: phoneNumber, onFullNumberChange, className, ...props },
    ref
  ) => {
    const fallbackId = useId();
    id = id ?? fallbackId;
    const [countryCode, setCountryCode] = useState("+1");

    useEffect(() => {
      onFullNumberChange({
        country_code: countryCode,
        phone_number: (phoneNumber as string) || "",
      });
    }, [countryCode, phoneNumber, onFullNumberChange]);

    const countryCodeInputId = useId();
    const countryCodeSelectId = useId();

    return (
      <div className="group">
        <label
          htmlFor={id}
          className={twJoin("fdc-input-field-label", phoneNumber && "fdc-input-field-label-active")}
        >
          <span>
            {label}
            {required && "*"}
          </span>
        </label>
        <div
          className={twJoin(
            "fdc-input-field-wrapper relative flex",
            error && "fdc-input-field-error"
          )}
        >
          <label htmlFor={countryCodeInputId} className="sr-only">
            Enter country code
          </label>
          <input
            id={countryCodeInputId}
            type="tel"
            list={countryCodeSelectId}
            value={countryCode}
            onChange={(e) => setCountryCode(e.target.value)}
            className="w-12 rounded-r-none py-[14px] pr-1 outline-none transition-all duration-300 bevel-clip-left focus:bevel-clip-none group-focus-within:bevel-clip-none -mr-[1px] text-black text-right"
          />
          <label htmlFor={countryCodeSelectId} className="sr-only">
            Select country code
          </label>
          <select
            id={countryCodeSelectId}
            className={`${styles.countrySelect} w-4 appearance-none bg-white bg-[length:0.75rem_0.75rem] bg-center bg-no-repeat text-transparent outline-none [&::--webkit-appearance]:appearance-none [&::-moz-appearance]:appearance-none`}
            onChange={(e) => setCountryCode(e.target.value)}
          >
            <option value="" disabled>
              Select country code
            </option>
            <option value="+1">United States (+1)</option>
            <option value="+44">United Kingdom (+44)</option>
            <option value="" disabled>
              –
            </option>
            {COUNTRY_CODES.map((country) => (
              <option key={`${country.code}-${country.phone_code}`} value={country.phone_code}>
                {`${country.name} (${country.phone_code})`}
              </option>
            ))}
          </select>

          <input
            type="tel"
            ref={ref}
            placeholder={`${label}${required ? "*" : ""}`}
            className={twMerge(
              "fdc-input-field bevel-clip-right group-focus-within:bevel-clip-none",
              "[appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none",
              className
            )}
            value={phoneNumber}
            {...props}
            aria-invalid={error ? true : false}
            aria-describedby={error ? `error-message-${id}` : undefined}
          />
        </div>
        {error && (
          <p id={`error-message-${id}`} className="fdc-input-field-error-text">
            {error}
          </p>
        )}
      </div>
    );
  }
);

PhoneInput.displayName = "PhoneInput";

export interface ControlledPhoneInputProps extends PhoneInputProps {
  name: string;
}

const ControlledPhoneInput = ({ name, ...props }: ControlledPhoneInputProps) => {
  const { control, formState } = useFormContext();
  const error = formState.errors[name];
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => <PhoneInput {...field} {...props} error={error?.message as string} />}
    />
  );
};
export default ControlledPhoneInput;
