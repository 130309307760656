import React, { forwardRef, useId } from "react";

import { Controller, useFormContext } from "react-hook-form";

import { CheckboxProps } from "../Checkbox";

interface SwitchInputProps extends CheckboxProps {}

const SwitchInput = forwardRef<HTMLInputElement, SwitchInputProps>(
  ({ label, id, ...props }, ref) => {
    const fallbackId = useId();
    id = id ?? fallbackId;
    return (
      <label
        htmlFor={id}
        className="fdc-label relative  flex  cursor-pointer gap-[0.8em] font-medium leading-7"
      >
        <input
          {...props}
          type="checkbox"
          id={id}
          ref={ref}
          className="fdc-switch-input before:content-[' '] after:content-[' '] m-0 box-border grid min-w-[3.5rem] appearance-none before:h-[2rem] before:w-[4rem] before:bg-dark before:opacity-20 after:absolute after:left-0 after:top-0 after:h-[2rem] after:w-[4rem] after:scale-[0.9] after:bg-white focus:outline-none"
        />
        <span className="fdc-switch-input__toggle absolute left-[0.4rem] top-[0.245rem] h-6 w-6 transform bg-dark opacity-20 transition duration-[375ms] ease-quick-in" />
        <span className="fdc-label-text opacity-60">{label}</span>
      </label>
    );
  }
);

SwitchInput.displayName = "SwitchInput";

export interface ControlledSwitchInputProps extends SwitchInputProps {
  name: string;
}

const ControlledSwitchInput = ({ name, ...props }: ControlledSwitchInputProps) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => <SwitchInput {...props} {...field} checked={field.value} />}
    />
  );
};

export default ControlledSwitchInput;
