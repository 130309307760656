/**
 * Base API Class to run HTTP requests.
 *
 * @export
 * @class API
 */

export type ApiStateType = "idle" | "pending" | "resolved" | "rejected";
export interface ApiErrorResponse {
  errors: ApiError[];
  error_type: ApiErrorType;
}
interface ApiError {
  field: string;
  message: string;
}

type ApiErrorType = "ValidationError";
export default class API {
  constructor(public debug: boolean = false) {}

  async get<T>(url: string, params: Record<string, string | number | boolean> = {}) {
    const searchParams = new URLSearchParams();

    Object.keys(params).forEach(
      (key) => params[key] && searchParams.append(key, params[key].toString())
    );

    const fullUrl = `${url}?${searchParams.toString()}`;

    if (this.debug) console.log(fullUrl);

    const response = await fetch(fullUrl);
    const json = (await response.json()) as T;
    return json;
  }

  async post<T>(
    url: string,
    payload: Record<string, string | number | boolean>,
    header: Record<string, string> = {}
  ) {
    if (this.debug) console.log(payload);

    let response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
        ...header,
      },
    });

    const json = await response.json();

    if (response?.ok) {
      return json as T;
    } else {
      throw json as ApiErrorResponse;
    }
  }
}
