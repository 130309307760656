import { useRef, useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitHandler, useForm, FormProvider, Resolver } from "react-hook-form";
import * as Yup from "yup";

import Button from "components/Button";
import PhoneInput, { PhoneNumberParams } from "components/input/PhoneInput";
import SwitchInput from "components/input/SwitchInput";
import TextArea from "components/input/TextArea";
import TextInput from "components/input/TextInput";

import API, { ApiErrorResponse, ApiStateType } from "lib/api/api";

import AnalyticsService from "services/analytics";

type FormValues = {
  name: string;
  email: string;
  phone_number: string;
  location: string;
  company: string;
  website: string;
  synopsis: string;
  opt_in_for_newsletter: boolean;
  web_referral_resource?: string;
  form_location?: string;
  org_name?: string;
};

interface ContactFormProps {
  handleSuccess: () => void;
}

const validationSchema = Yup.object({
  phone_number: Yup.string().matches(/^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$/, {
    message: "Invalid phone number",
    excludeEmptyString: true,
  }),
  name: Yup.string().required("Your name is required"),
  email: Yup.string().email("Email is not valid").required("Email is required"),
  synopsis: Yup.string().min(10, "10 characters minimum").required("Project synopsis is required"),
  website: Yup.string().matches(
    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/,
    {
      message: "Invalid URL, Example: https://fueled.com",
      excludeEmptyString: true,
    }
  ),
  location: Yup.string(),
  company: Yup.string(),
  opt_in_for_newsletter: Yup.boolean(),
});

const CONTACT_FORM_URL_MAP = {
  sandbox: "https://qa.contacts-service.fueled.com/api/contact-request/",
  production: "https://contacts-service.fueled.com/api/contact-request/",
};

const ContactForm = ({ handleSuccess }: ContactFormProps) => {
  const phoneRef = useRef<PhoneNumberParams>({
    country_code: "",
    phone_number: "",
  });

  const [apiState, setApiState] = useState<ApiStateType>("idle");
  const [errorMessage, setErrorMessage] = useState<string>("");

  const methods = useForm<FormValues>({
    mode: "onTouched",
    defaultValues: {
      name: "",
      email: "",
      phone_number: "",
      location: "",
      company: "",
      website: "",
      synopsis: "",
      opt_in_for_newsletter: true,
    },
    resolver: yupResolver(validationSchema) as Resolver<FormValues>,
  });

  const onSubmit: SubmitHandler<FormValues> = async (values) => {
    const payload: FormValues = {
      name: values.name,
      phone_number: phoneRef.current.country_code + phoneRef.current.phone_number,
      email: values.email,
      location: values.location,
      website: values.website,
      synopsis: values.synopsis,
      opt_in_for_newsletter: values.opt_in_for_newsletter,
      company: values.company,
      org_name: values.company,
      form_location: "FDC",
      web_referral_resource: document.referrer || "Unknown",
    };

    try {
      setApiState("pending");
      const isProduction = process.env.NODE_ENV === "production";
      const url = isProduction ? CONTACT_FORM_URL_MAP.production : CONTACT_FORM_URL_MAP.sandbox;
      const $api = new API();
      await $api.post(url, payload);
      AnalyticsService.trackContactSubmit();
      setApiState("resolved");
      handleSuccess();
    } catch (error) {
      const _error = error as ApiErrorResponse;
      if (_error?.error_type === "ValidationError") {
        setErrorMessage("Please fill the required fields");
      } else {
        setErrorMessage(_error?.errors[0]?.message);
      }
      setApiState("rejected");
    }
  };

  return (
    <FormProvider {...methods}>
      <form
        className="mx-auto max-w-7xl not-prose px-4 md:px-6"
        onSubmit={methods.handleSubmit(onSubmit)}
        noValidate
      >
        <fieldset>
          <div className="mb-2 flex justify-between md:mb-3">
            <legend className="h4 font-semibold">Project Info</legend>
            <div className="hidden lg:block">
              <SwitchInput name="opt_in_for_newsletter" label="Add me to the Fueled Mailing list" />
            </div>
          </div>
          <div className="grid grid-cols-12 gap-x-6 gap-y-2">
            <div className="col-span-12 md:col-span-6 lg:col-span-4">
              <TextInput label="Name" name="name" required />
            </div>
            <div className="col-span-12 md:col-span-6 lg:col-span-4">
              <TextInput label="Email" type="email" name="email" required />
            </div>
            <div className="col-span-12 md:col-span-6 lg:col-span-4">
              <PhoneInput
                label="phone number"
                name="phone"
                onFullNumberChange={(phone) => {
                  phoneRef.current = phone;
                }}
              />
            </div>
            <div className="col-span-12 md:col-span-6 lg:col-span-4">
              <TextInput label="location" name="location" />
            </div>
            <div className="col-span-12 md:col-span-6 lg:col-span-4">
              <TextInput label="company" name="company" />
            </div>
            <div className="col-span-12 md:col-span-6 lg:col-span-4">
              <TextInput label="website" type="url" name="website" />
            </div>
          </div>
          <div className="my-4 grid grid-cols-12 gap-y-8 lg:my-8 lg:gap-x-6 lg:gap-y-0">
            <div className="col-span-12 lg:col-span-8">
              <TextArea label="Project synopsis" name="synopsis" required />
            </div>
            <div className="col-span-12 flex flex-col justify-between space-y-8 md:flex-row md:space-y-0 lg:col-span-4 lg:justify-end">
              <div className="aria-hidden: block lg:hidden">
                <SwitchInput
                  name="opt_in_for_newsletter"
                  label="Add me to the Fueled Mailing list"
                />
              </div>
              <div className="md:self-end">
                <Button
                  label="submit"
                  type="submit"
                  size="large"
                  variant="outlined"
                  colorScheme="accent"
                  loading={apiState === "pending"}
                />
              </div>
            </div>
          </div>
          {apiState === "rejected" && <p className="body-xs text-brand-700">{errorMessage}</p>}
        </fieldset>
      </form>
    </FormProvider>
  );
};

export default ContactForm;
