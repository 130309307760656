import React, { forwardRef, useId } from "react";

import { Controller, useFormContext } from "react-hook-form";
import { twJoin, twMerge } from "tailwind-merge";

interface TextInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  required?: boolean;
  error?: string;
}

const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  ({ label = "", error, value, id, required, className, ...props }, ref) => {
    const fallbackId = useId();
    id = id ?? fallbackId;
    return (
      <div className="group">
        <label
          htmlFor={id}
          className={twJoin(
            "fdc-input-field-label col-span-12",
            value && "fdc-input-field-label-active"
          )}
        >
          <span>
            {label}
            {required && "*"}
          </span>
        </label>
        <div className={twJoin("fdc-input-field-wrapper", error && "fdc-input-field-error")}>
          <input
            {...props}
            id={id}
            ref={ref}
            value={value}
            placeholder={`${label}${required ? "*" : ""}`}
            className={twMerge("fdc-input-field", className)}
            aria-invalid={error ? true : false}
            aria-describedby={error ? `error-message-${id}` : undefined}
          />
        </div>
        {error && (
          <p id={`error-message-${id}`} className="fdc-input-field-error-text">
            {error}
          </p>
        )}
      </div>
    );
  }
);

TextInput.displayName = "TextInput";

export interface ControlledTextInputProps extends TextInputProps {
  name: string;
}

const ControlledTextInput = ({ name, ...props }: ControlledTextInputProps) => {
  const { control, formState } = useFormContext();
  const error = formState.errors[name];
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => <TextInput {...field} {...props} error={error?.message as string} />}
    />
  );
};

export default ControlledTextInput;
